import React from 'react'
import InputMask from 'react-input-mask'
import PropTypes from "prop-types"
import parse from 'html-react-parser'
import request from "../utils/request"
import Modal from 'react-bootstrap/Modal'
import { normalize, schema } from 'normalizr'
import produce from 'immer'

export default class Form extends React.Component {
    static propTypes = {
        formName: PropTypes.string,
        formItems: PropTypes.array,
        hideTitle: PropTypes.bool,
        formSubmitUrl: PropTypes.string,
        formShowLabel: PropTypes.bool,
        submitText: PropTypes.string,
        modal: PropTypes.bool,
        modalButtonText: PropTypes.string,
        setFormValues: PropTypes.object,
        template: PropTypes.string,
        callTouchModId: PropTypes.string,
    }

    static defaultProps = {
        formName: '',
        formItems: [],
        hideTitle: false,
        formSubmitUrl: '',
        submitText: 'Отправить',
        modal: false,
        modalButtonText: '',
        formShowLabel: false,
        setFormValues: {},
        template: '',
        callTouchModId: 'eocaulym',
    }

    state = {
        error: "",
        loading: true,
        submitting: false,
        send: false,
        formData: {},
        isOpen: false,
    };

    componentDidMount() {
        const formItem = new schema.Entity('item', {}, { idAttribute: (value) => value.code });
        const normalizedData = normalize(this.props.formItems, [formItem]);
        //persist preset values
        for (const code of normalizedData.result) {
            if (this.props.setFormValues[code]) {
                normalizedData.entities.item[code].userValue = this.props.setFormValues[code];
            }
        }
        this.setState({ formData: normalizedData, loading: false });
    }

    renderField = (code) => {
        if (!this.state.formData.entities.item[code]) {
            return null
        }
        //hide preset values
        if (this.props.setFormValues[code]) {
            return null
        }
        const { id, title, type, value, error = "", isRequired, isPhone } = this.state.formData.entities.item[code];
        let labelTitle = title
        if (isRequired) {
            labelTitle = `${title}*`
        }
        switch (type) {
            case 'select':
                let select = "";
                select += `<option value="">${title}</option>`;
                for (const item of value) {
                    select += `<option value=${item.alternative_id}>${item.value}</option>`;
                }

                return (
                    <div key={id} className={(error.length > 0) ? "form-group form-group-error" : "form-group"}>
                        <div className="form__block">
                            {(this.props.formShowLabel)
                                ?
                                <label
                                    htmlFor={`for${code}`}
                                    className="form__label"
                                >
                                    {labelTitle}
                                </label>
                                : null
                            }
                            <select
                                onBlur={event => this.handleChange(event, code)}
                                className="form-control form__select"
                                placeholder={labelTitle}
                                name={code}
                                dangerouslySetInnerHTML={{ __html: select }}
                            >
                            </select>
                        </div>
                        {(error.length > 0) && <span className="error">{error}</span>}
                    </div>
                )

            case "checkbox": 
                return (
                    <div key={id} className={(error.length > 0) ? "form-group form-check form-group-error" : "form-check form-group"}>
                        <div className="form__block">
                            <input
                                id={`for${code}`}
                                className="form-check-input"
                                checked={(this.state.formData.entities.item[code].userValue.length > 0)}
                                type={type}
                                name={code}
                                value={value[0].alternative_id}
                                onChange={event => this.handleChange(event, code)}
                            />
                            <label 
                                className="form-check-label"
                                htmlFor={`for${code}`}
                                dangerouslySetInnerHTML={{ __html: labelTitle }}
                            >
                              
                            </label>
                        </div>
                        {(error.length > 0) && <span className="error">{error}</span>}
                    </div>
                )

            case "textarea":
                return (
                    <div key={id} className={(error.length > 0) ? "form-group form-group-error" : "form-group"}>
                        <div className="form__block">
                            {(this.props.formShowLabel)
                                ?
                                <label
                                    htmlFor={`for${code}`}
                                    className="form__label"
                                >
                                    {labelTitle}
                                </label>
                                : null
                            }
                            <textarea
                                placeholder={labelTitle}
                                className="form-control"
                                name={code}
                                onChange={event => this.handleChange(event, code)}
                                value={this.state.formData.entities.item[code].userValue}
                            />
                        </div>
                        {(error.length > 0) && <span className="error">{error}</span>}
                    </div>
                )

            default:
                return (
                    <div key={id} className={(error.length > 0) ? "form-group form-group-error" : "form-group"}>
                        <div className="form__block">
                            {(this.props.formShowLabel)
                                ?
                                <label
                                    htmlFor={`for${code}`}
                                    className="form__label"
                                >
                                    {labelTitle}
                                </label>
                                : null
                            }
                            {
                                (isPhone)
                                    ?
                                    <InputMask
                                        mask="+7 (999) 999-99-99"
                                        placeholder={labelTitle}
                                        value={this.state.formData.entities.item[code].userValue}
                                        className="form-control"
                                        type={type}
                                        name={code}
                                        onChange={event => this.handleChange(event, code)}
                                    />
                                    :
                                    <input
                                        className="form-control"
                                        type={type}
                                        name={code}
                                        value={this.state.formData.entities.item[code].userValue}
                                        placeholder={labelTitle}
                                        onChange={event => this.handleChange(event, code)}
                                    />
                            }
                        </div>
                        {(error.length > 0) && <span className="error">{error}</span>}
                    </div>
                )
        }
    };

    validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    validate = (code, value) => {
        if (!this.state.formData.entities.item[code]) {
            return null
        }
        const res = { success: true, error: "" }
        const { isRequired = false, isEmail = false, isPhone = false } = this.state.formData.entities.item[code]
        if (isRequired && value.length === 0) {
            res.error = "Значение обязательно для заполнения"
            res.success = false
        }
        if (isEmail && !this.validateEmail(value)) {
            res.error = "Вы указали неверный email"
            res.success = false
        }

        if (isPhone && value.replace(/\D/g, "").length < 11) {
            res.error = "Вы указали неверный номер телефона"
            res.success = false
        }
        return res;
    };

    handleChange = (event, code) => {
        if (!this.state.formData.entities.item[code]) {
            return null
        }
        const { type, userValue } = this.state.formData.entities.item[code]
        const validate = this.validate(code, event.target.value)
        const item = {
            error: validate.error,
            userValue: ""
        };
 
        if (type === "checkbox") {
            if (!userValue || userValue === "") {
                item.userValue = event.target.value
            } else {
                item.userValue = ""
            }
        } else if (type === 'file') {
            item.userValue = event.target.value;
            item.sourceFile = event.target.files;
        } else {
            item.userValue = event.target.value
        }
        this.setState(
            produce(newState => {
                newState.formData.entities.item[code] = {
                    ...newState.formData.entities.item[code],
                    ...item
                };
            })
        )
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let success = true
        for (const code of this.state.formData.result) {
            const res = this.validate(code, this.state.formData.entities.item[code].userValue)
            if (!res.success) {
                success = false
            }
        }

        if (!success) {
            this.setState(
                produce(newState => {
                    for (const code of newState.formData.result) {
                        const res = this.validate(code, newState.formData.entities.item[code].userValue)
                        if (!res.success) {
                            success = false;
                            newState.formData.entities.item[code].error = res.error;
                        }
                    }
                })
            )
            return false
        }

        const formData = new FormData();
        for (const code of this.state.formData.result) {
            const { alternative_id, userValue, type, sourceFile } = this.state.formData.entities.item[code]
            if (type === 'file') {
                formData.append(code, sourceFile[0])
            } else {
                formData.append(`value[${alternative_id}]`, userValue)
            }
        }
        // add CallTouch sessionId
        const {sessionId: callTouchSessionId} = window?.ct('calltracking_params', this.props.callTouchModId);
        if(callTouchSessionId) {
            console.log('callTouchSessionId: ', callTouchSessionId);
            formData.append(`callTouchSessionId`, callTouchSessionId);
        }
        this.setState({ submitting: true });
        const res = await request(this.props.formSubmitUrl, {
            method: 'POST',
            body: formData
        });
        if (!res.success) {
            this.setState({ submitting: false, send: false })
            return
        }

        if (!res.data.send) {
            this.setState({ submitting: false, send: false })
            // this.setState(
            //     produce(newState => {
            //         newState.submitting = false
            //         newState.send = false
            //         for (const resItem of res.data.items) {
            //             newState.formData.entities.item[resItem.code].error = resItem.error;
            //         }
            //     })
            // )
        } else {
            this.setState({ submitting: false, send: true })
        }
    }

    toggleForm = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    renderFields = () => {
        // if template exist
        if (this.props.template) {
            const form = { SUBMIT: this.getSubmitBtn() }
            for (const code of this.state.formData.result) {
                form[code] = this.renderField(code)
            }
            return parse(this.props.template, {
                replace: ({ attribs }) => {
                    if (attribs && form[attribs.id]) {
                        return form[attribs.id]
                    }
                }
            })
        }
        //without template
        const form = []
        for (const code of this.state.formData.result) {
            form.push(this.renderField(code))
        }
        form.push(this.getSubmitBtn())
        return form
    }

    getSubmitBtn = () => (
        <>
            {
                (this.state.submitting)
                    ?
                    <div className="osomeform-submitting">
                        <div class="lds-dual-ring"></div>
                    </div>
                    :
                    <div className="osomeform-submit form-group text-center">
                        <button type="submit" className="btn">{this.props.submitText}</button>
                    </div>
            }
        </>
    )


    formSuccess = () => (
        <div className="osomeform-send-success">
            <h1>Сообщение успешно отправлено!</h1>
            <p>Спасибо за обращение! Мы обязательно свяжемся с Вами в ближайшее время.</p>
        </div>
    )

    formBody = () => (
        <form method="post" onSubmit={this.handleSubmit}>
            {(!this.props.hideTitle) && <h3 className="header">{this.props.formName}</h3>}
            {this.renderFields()}
        </form>
    )

    render() {
        if (this.state.loading) {
            return <div className="osomeform-loading" />
        }

        if (this.state.error.length > 0) {
            return <div className="form-wrapper osomeform">{this.state.error}</div>
        }

        if (this.props.modal) {
            return (
                <div>
                    <button
                        onClick={this.toggleForm}
                        className={(this.props.className) ? `btn ${this.props.className}` : "btn"}>
                        {this.props.modalButtonText}
                    </button>

                    <Modal show={this.state.isOpen} onHide={this.toggleForm}>
                        <Modal.Header closeButton />
                        <Modal.Body>
                            <div className={`form-wrapper form-modal osomeform`}>
                                {(this.state.send) ? this.formSuccess() : this.formBody()}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        }

        return (
            <div className="form-wrapper osomeform">
                {(this.state.send) ? this.formSuccess() : this.formBody()}
            </div>
        )
    }
}