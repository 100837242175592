import React, { Component } from 'react'
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Carousel from "react-bootstrap/Carousel"
import Form from "./form"
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash';

class Slider extends Component {
  static propTypes = {
    items: PropTypes.array,
    formData: PropTypes.object.isRequired,
  }

  static defaultProps = {
    items: [],
  }

  state = {
    isOpen: false,
    item: {},
  }

  openModal = (item) => {
    //this.setState({ isOpen: true, item });
    document.getElementById('car-price').getElementsByClassName('btn')[0].click();
  }

  render() {
    return (
      <div className="hero">
        <Carousel>
          {this.props.items.map(item => {
            let position = '';
            switch (item.props?.horizontal?.value) {
              case 'Слева':
                position = 'text-left';
                break;
              case 'Справа':
                position = 'text-right';
                break;
              case 'По центру':
                position = 'text-center';
                break;
              default:
                position = 'text-left';
            }
            return (
              <Carousel.Item key={item.id} onClick={() => this.openModal(item)}>
                <Img fluid={[
                  {
                    ...item.localImageDesktop.childImageSharp.fluid,
                    media: `(max-width: 2680px)`,
                  }
                ]} />
                {(item.props?.txtLine?.value) &&
                  <Carousel.Caption className={`carousel-caption d-md-block ${position}`}>
                    <div className="carousel-caption__block">
                      <h3 className="carousel-caption__line">{item.props?.txtLine?.value}</h3>
                      <h4 className="carousel-caption__subline">{item.props?.txtSubline?.value}</h4>
                      {/* <button class="btn">Узнать подробнее</button> */}

                    </div>
                  </Carousel.Caption>
                }
              </Carousel.Item>
            )
          })}
        </Carousel>

        <Modal show={this.state.isOpen} onHide={() => this.setState({ isOpen: false, item: {} })}>
          <Modal.Header className="black" closeButton />
          {
            (!_.isEmpty(this.state.item))
            &&
            <div className="slider-modal">
              <Img fluid={
                [
                  {
                    ...this.state.item.localImageDesktop.childImageSharp.fluid,
                    media: `(max-width: 2680px)`,
                  }
                ]
              } />
              <h2 className="slider-modal__title">{this.state.item.name}</h2>

              <div className="slider-modal__desc" dangerouslySetInnerHTML={{ __html: this.state.item.detailText }} />

              {/* <Form
                formName={this.props.formData.name}
                formItems={this.props.formData.items}
                template={this.props.formData.template}
                formSubmitUrl="https://api.osome.dev/api/v1/form?id=80"
                formShowLabel={false}
                modal={false}
                modalButtonText="Заказать звонок"
                hideTitle={false}
                submitText="Заказать звонок"
              /> */}

              <Form 
                formName={this.props.formData.name}
                formItems={this.props.formData.items}
                template={this.props.formData.template}
                formSubmitUrl="https://api.osome.dev/api/v1/form?id=81"
                formShowLabel={false}
                modal={true}
                modalButtonText="Оценить автомобиль"
                hideTitle={true}
                submitText="Оценить автомобиль"
              />     
            </div>
          }
        </Modal>
      </div>
    )
  }
}

export default Slider